import { PlayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { VideoSearchResult } from 'yt-search';
import ContextMenu from './ContextMenu';
import SongDisplay from './SongDisplay';

type Props = {
    song: VideoSearchResult;
    queueItem: (song: VideoSearchResult) => void;
    removeFromFavorites: (song: VideoSearchResult) => void;
    direction?: 'horizontal' | 'vertical';
};

const FavoriteSongDisplay = (props: Props) => {
    const { song, queueItem, removeFromFavorites } = props;

    const [open, setOpen] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0 });

    return (
        <div
            onContextMenu={(e) => {
                e.preventDefault();
                setOpen(true);
                setPos({ x: e.clientX, y: e.clientY });
            }}
        >
            <ContextMenu
                open={open}
                setOpen={setOpen}
                pos={pos}
                options={[
                    {
                        icon: <PlayIcon className="w-6 h-6" />,
                        content: 'Queue song',
                        onClick: () => {
                            queueItem(song);
                        }
                    },
                    {
                        icon: <XMarkIcon className="w-6 h-6" />,
                        content: 'Remove from favorites',
                        onClick: () => removeFromFavorites(song)
                    }
                ]}
            />
            <SongDisplay song={song} />
        </div>
    );
};

export default FavoriteSongDisplay;
