import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Socket } from 'socket.io-client';

type Props = {
    ws: Socket;
};

const StatusIndicator = (props: Props) => {
    const { ws } = props;

    return (
        <div className="status-indicator">
            Websocket Status:
            {ws.connected && <CheckIcon className="w-6 h-6 text-green-500" />}
            {!ws.connected && <XMarkIcon className="w-6 h-6 text-red-500" />}
        </div>
    );
};

export default StatusIndicator;
