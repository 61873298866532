import { VideoSearchResult } from 'yt-search';
import { MessageType } from '../../../common/ws';
import SongDisplay from '../common/SongDisplay';

type Props = {
    history: VideoSearchResult[];
    send: (data: string, type: MessageType) => void;
};

const History = (props: Props) => {
    const { history, send } = props;
    const queueItem = (song: VideoSearchResult) => {
        props.send(song.videoId, 'request');
    };

    return (
        <div className="history">
            <h1 className="text-4xl">History</h1>
            <div className="history-list">
                {history.map((song, index) => (
                    <div className="history-item" key={index}>
                        <SongDisplay
                            song={song}
                            onClick={queueItem}
                            direction="vertical"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default History;
