type Props = {
    onClick: () => void;
    children: React.ReactNode;
};

const ContextMenuItem = (props: Props) => {
    const { onClick, children } = props;

    return (
        <div className="context-menu-item" onClick={onClick}>
            {children}
        </div>
    );
};

export default ContextMenuItem;
