import {
    ForwardIcon,
    HeartIcon as HollowHeart
} from '@heroicons/react/24/outline';
import { HeartIcon as FullHeart } from '@heroicons/react/24/solid';
import { VideoSearchResult } from 'yt-search';
import { MessageType } from '../../../common/ws';
import ButtonBar from '../common/ButtonBar';

type Props = {
    send: (data: string, type: MessageType) => void;
    song?: VideoSearchResult;
    favorites: VideoSearchResult[];
    setFavorites: (favorites: VideoSearchResult[]) => void;
};

const CurrentSongDisplay = (props: Props) => {
    const { send, song, favorites, setFavorites } = props;
    if (!song) {
        return <div></div>;
    }

    const isFavorited = favorites.some(
        (favorite) => favorite.videoId === song.videoId
    );

    const toggleFavorited = () => {
        if (isFavorited) {
            const newFavorites = favorites.filter(
                (favorite) => favorite.videoId !== song.videoId
            );
            setFavorites(newFavorites);
            return;
        }

        setFavorites([...favorites, song]);
    };

    const sendSkip = () => {
        send('', 'skip');
    };

    return (
        <div className="current-song-display">
            <img
                className="player-thumbnail"
                src={song.thumbnail}
                alt={song.title}
            />
            <h1 className="mt-4 text-2xl">{song.title}</h1>
            <ButtonBar>
                <button
                    onClick={toggleFavorited}
                    className="hover:text-red-500"
                >
                    {isFavorited && <FullHeart className="w-6 h-" />}
                    {!isFavorited && <HollowHeart className="w-6 h-6" />}
                </button>
                <button onClick={sendSkip}>
                    <ForwardIcon className="w-6 h-6" />
                </button>
            </ButtonBar>
        </div>
    );
};

export default CurrentSongDisplay;
