import { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { VideoSearchResult } from 'yt-search';
import { MessageType, WebSocketMessage } from '../../common/ws';
import { SocketIOEvents } from './common/types';
import CurrentSongDisplay from './components/CurrentSongDisplay';
import FavoritesDisplay from './components/FavoritesDisplay';
import History from './components/History';
import QueueDisplay from './components/QueueDisplay';
import SearchPanel from './components/SearchPanel';
import StatusIndicator from './components/StatusIndicator';

const PORT = 6993;
const URL = `wss://142.196.224.228:${PORT}`;
// const URL = `ws://localhost:${PORT}/`;
const ws: Socket<SocketIOEvents> = io(URL);

const send = (data: string, type: MessageType) => {
    console.log(data);

    const toSend: WebSocketMessage = {
        from: 'frontend',
        type,
        data
    };

    ws.send(JSON.stringify(toSend));
};

function App() {
    const [queue, setQueue] = useState<VideoSearchResult[]>([]);
    const [favorites, setFavorites] = useState<VideoSearchResult[]>(
        JSON.parse(
            localStorage.getItem('favorites') || '[]'
        ) as VideoSearchResult[]
    );
    const [nowPlaying, setNowPlaying] = useState<
        VideoSearchResult | undefined
    >();
    const [history, setHistory] = useState<VideoSearchResult[]>([]);

    useEffect(() => {
        ws.on('message', (message) => {
            message = JSON.parse(message + '') as WebSocketMessage;
            console.log(message);
            if (!message.data) {
                return;
            }

            if (message.type === 'queue') {
                setQueue(message.data as VideoSearchResult[]);
            }

            if (message.type === 'playing') {
                setNowPlaying(message.data as VideoSearchResult);
            }

            if (message.type === 'history') {
                setHistory(message.data as VideoSearchResult[]);
            }
        });
        ws.on('connect', () => {
            send('frontend connected', 'handshake');
        });
    }, []);

    useEffect(() => {
        localStorage.setItem('favorites', JSON.stringify(favorites));
    }, [favorites]);

    return (
        <div className="w-full h-full flex flex-col max-h-[100vh]">
            <SearchPanel send={send} />
            <main className="flex max-h-[90%] w-full mainContainer">
                <FavoritesDisplay
                    favorites={favorites}
                    send={send}
                    removeFromFavorites={(song) => {
                        setFavorites(
                            favorites.filter(
                                (favorite) => favorite.videoId !== song.videoId
                            )
                        );
                    }}
                />
                <CurrentSongDisplay
                    send={send}
                    song={nowPlaying}
                    favorites={favorites}
                    setFavorites={setFavorites}
                />
                <QueueDisplay queue={queue} />
            </main>
            <History history={history} send={send} />
            <StatusIndicator ws={ws} />
        </div>
    );
}

export default App;
