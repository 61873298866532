import { MagnifyingGlassIcon as SearchIcon } from '@heroicons/react/24/outline';
import { MessageType } from '../../../common/ws';

type Props = {
    send: (data: string, type: MessageType) => void;
};

const SearchPanel = (props: Props) => {
    const { send } = props;

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const input = event.currentTarget[0] as HTMLInputElement;

        const songName = input.value;

        if (songName) {
            send(songName.toString(), 'request');
        }

        input.value = '';
    };

    return (
        <form onSubmit={handleSubmit} className="search-form my-4 mx-auto">
            <input type="text" className="search-input" placeholder="Search" />
            <button type="submit" className="bg-tertiary px-4">
                <SearchIcon className="w-6 h-6" />
            </button>
        </form>
    );
};

export default SearchPanel;
