import { VideoSearchResult } from 'yt-search';

type Props = {
    song: VideoSearchResult;
    onClick?: (song: VideoSearchResult) => void;
    direction?: 'horizontal' | 'vertical';
};

const SongDisplay = (props: Props) => {
    const { song, onClick } = props;

    return (
        <div
            className={`song-display ${props.direction ?? 'horizontal'}`}
            onClick={() => {
                if (onClick) {
                    onClick(song);
                }
            }}
        >
            <img className="thumbnail" src={song.thumbnail} alt={song.title} />
            <h2 className="text-xl">{song.title}</h2>
        </div>
    );
};

export default SongDisplay;
