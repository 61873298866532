import { VideoSearchResult } from 'yt-search';
import SongDisplay from '../common/SongDisplay';

type Props = {
    queue: VideoSearchResult[];
};

const QueueDisplay = (props: Props) => {
    const { queue } = props;

    return (
        <div className="queue-display">
            <h1 className="text-4xl">Queue</h1>
            <ul className="flex flex-col gap-2">
                {queue.map((song, index) => (
                    <SongDisplay key={index} song={song} />
                ))}
            </ul>
        </div>
    );
};

export default QueueDisplay;
