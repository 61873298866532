import React from 'react';

type Props = {
    children: React.ReactNode;
};

const ButtonBar = (props: Props) => {
    const { children } = props;

    return <div className="button-bar">{children}</div>;
};

export default ButtonBar;
