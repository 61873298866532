import { VideoSearchResult } from 'yt-search';
import { MessageType } from '../../../common/ws';
import FavoriteSongDisplay from '../common/FavoriteSongDisplay';

type Props = {
    favorites: VideoSearchResult[];
    send: (data: string, type: MessageType) => void;
    removeFromFavorites: (song: VideoSearchResult) => void;
};

const FavoritesDisplay = (props: Props) => {
    const { favorites, send, removeFromFavorites } = props;

    const queueItem = (song: VideoSearchResult) => {
        send(song.videoId, 'request');
    };

    return (
        <div className="favorites-display">
            <h1 className="text-4xl">Favorites</h1>
            <ul className="flex flex-col gap-2">
                {favorites.map((song, index) => (
                    <FavoriteSongDisplay
                        key={index}
                        song={song}
                        queueItem={queueItem}
                        removeFromFavorites={removeFromFavorites}
                    />
                ))}
            </ul>
        </div>
    );
};

export default FavoritesDisplay;
