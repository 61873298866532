import React from 'react';
import ContextMenuItem from './ContextMenuItem';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    pos: { x: number; y: number };
    options: { icon: React.ReactNode; content: string; onClick: () => void }[];
};

const ContextMenu = (props: Props) => {
    const { open, options } = props;

    if (!open) {
        return <> </>;
    }

    return (
        <div
            className="context-menu"
            style={{
                left: props.pos.x,
                top: props.pos.y
            }}
            onClick={() => props.setOpen(false)}
            onMouseLeave={() => props.setOpen(false)}
        >
            <div className="context-menu-content">
                <ul className="context-menu-menu">
                    {options.map((option, index) => (
                        <ContextMenuItem key={index} onClick={option.onClick}>
                            {option.icon}
                            <span>{option.content}</span>
                        </ContextMenuItem>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ContextMenu;
